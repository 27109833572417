<template>
  <div>
    <v-footer class="footer" color="primary">
      <div class="w-100">
        <v-row class="my-0">
          <v-col class="d-flex align-center justify-start centered-on-mobile" cols="12" sm="6">
            <NuxtLink href="https://lifeishard.ro/" target="_blank" aria-label="Life is Hard">
              <div class="logo-lih"></div>
            </NuxtLink>
          </v-col>
          <v-col class="d-flex align-center justify-end centered-on-mobile" cols="12" sm="6">
            <template v-for="(item, index) in socialMenuItems" :key="index">
              <NuxtLink :to="item.path" class="text-decoration-none color-contrast">
                <v-btn
                    rounded="0"
                    class="mx-1"
                    variant="tonal"
                    target="_blank"
                    :icon="item.icon"
                    :aria-label="item.name"
                >
                </v-btn>
              </NuxtLink>
            </template>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="visible-on-web d-flex align-center justify-start centered-on-mobile" cols="12" sm="6" md="4"
                 lg="4">
            <div>&copy; {{ new Date().getFullYear() }} Toate drepturile rezervate.</div>
          </v-col>
          <v-col class="d-flex flex-wrap justify-end centered-on-mobile" cols="12" sm="12" md="8" lg="8">
            <template v-for="(item, index) in menuItems" :key="index">
              <NuxtLink :to="item.path" class="text-decoration-none color-contrast" :aria-label="item.title">
                <v-btn
                    :class="{ 'btn-active': isBtnActive(item.path) }"
                    class="mx-1"
                    variant="text"
                    rounded="0"
                >
                  {{ item.title }}
                </v-btn>
              </NuxtLink>
            </template>
          </v-col>
          <v-col class="visible-on-mobile centered-on-mobile d-flex align-center" cols="12" sm="12" md="12" lg="12">
            <div>&copy; {{ new Date().getFullYear() }} Toate drepturile rezervate.</div>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0 d-flex align-center justify-start centered-on-mobile">
          <NuxtLink href="https://anpc.ro/ce-este-sal/" target="_blank" aria-label="ANPC">
            <NuxtImg
                src="/images/legal/anpc-sal.webp"
                style="max-width: 250px; max-height: 50px; min-width: 250px; min-height: 50px;"
                class="h-100 ma-1"
                loading="lazy"
                alt="Solutionarea Alternativa a Litigiilor"
            />
          </NuxtLink>
          <NuxtLink href="https://ec.europa.eu/consumers/odr" target="_blank" aria-label="Solutionarea Online a Litigiilor">
            <NuxtImg
                src="/images/legal/anpc-sol.webp"
                style="max-width: 250px; max-height: 50px; min-width: 250px; min-height: 50px;"
                class="h-100 ma-1"
                loading="lazy"
                alt="Solutionarea Online a Litigiilor"
            />
          </NuxtLink>
        </v-row>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, computed, ref} from 'vue';
import {useTranslationStore, translation} from '~/plugins/store/translations';
import {useRouter, useRoute} from 'vue-router';
import type {TranslationList} from "~/types/Utils";

export default defineComponent({
  setup() {
    const translationStore = useTranslationStore();
    const translationList = ref<TranslationList>({}); // Initialize translations as a reactive reference

    const route = useRoute();

    //Loading translations when component is mounted
    onMounted(async () => {
      translationList.value = await translationStore.getTranslations('ro', 'dashboard');
    });

    //Define menus
    const menuItems = ref([
      {
        title: translation(translationList.value.terms_and_conditions, 'Termeni și condiții'),
        path: "/info/terms-and-conditions"
      },
      {
        title: translation(translationList.value.privacy_policy, 'Politică de confidențialitate'),
        path: "/info/privacy-policy"
      },
      {
        title: translation(translationList.value.about_us, 'Despre noi'),
        path: "/info/about-us"
      },
      {
        title: translation(translationList.value.contact, 'Contact'),
        path: "/contact-us"
      },
    ]);

    //Define social menus
    const socialMenuItems = ref([
      {
        icon: 'mdi-linkedin',
        path: "https://lifeishard.ro/",
        name: 'LinkedIn',
      },
      {
        icon: 'mdi-facebook',
        path: "https://lifeishard.ro/",
        name: 'Facebook',
      },
      {
        icon: 'mdi-instagram',
        path: "https://lifeishard.ro/",
        name: 'Instagram',
      }
    ]);

    //Marking the menu buttons and menu dropdown buttons as active
    const isBtnActive = (path: String) => {
      // Check if the current path matches the defined path for the menu
      if (path && route.path === path) {
        return true;
      }
    };

    return {
      translationList,
      menuItems,
      socialMenuItems,
      translation,
      isBtnActive,
    };
  },
});
</script>


<style scoped>

</style>
